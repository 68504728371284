<template>
  <section class="w-full absolute explorer__start--height" :class="inStep(1) ? 'explorer__start__account' : ''">
    <div class="w-full h-full">
      <PreexistentUserWelcome
        v-if="inStep(1)"
        pre-created
        :research-locale-key-root="researchLocaleKeyRoot"
        :research-data="researchData"
        :research-logos="researchLogos"
        @nextStep="nextStep"
      />
      <PreexistentUserPassword v-if="inStep(2)" @nextStep="nextStep" @backStep="backStep()" />
      <PreexistentUserConfirmation
        v-if="inStep(3)"
        :research-locale-key-root="researchLocaleKeyRoot"
        :research-data="researchData"
        :research-logos="researchLogos"
        @nextStep="nextStep"
        @backStep="backStep()"
      />
      <LocationFlow
        v-if="inStep(4)"
        :class="!hasLocation ? 'w-full h-full absolute' : ''"
        :select-step="hasLocation ? 2 : 1"
        in-welcome
        back
        :is-draggable="isDraggable()"
        :has-initial-location="hasInitialLocation"
        :research-locale-key-root="researchLocaleKeyRoot"
        pre-created
        @closeModal="nextStep"
        @backStep="backStep()"
        @changeLocation="changeLocation"
        @locationSelected="locationSelected"
      />
      <div
        v-show="inStep(5)"
        id="research-iframe-container"
        class="w-full d-flex h-full"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PreexistentUserPassword from '@/components/organisms/preexist_register_new/PreexistentUserPassword.vue';
import PreexistentUserWelcome from '@/components/organisms/preexist_register_new/PreexistentUserWelcome.vue';
import PreexistentUserConfirmation from '@/components/organisms/preexist_register_new/PreexistentUserConfirmation.vue';
import trackMixPanel from '@/utils/mixpanel';
import { matchScholarships } from '@/utils/scholarships';
import CONFIG from '@/config';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import reportsService from '@/services/reports.service';
import LocationFlow from './LocationFlow.vue';

export default {
  name: 'PreexistentUserLoginFlow',
  components: {
    PreexistentUserWelcome,
    PreexistentUserPassword,
    PreexistentUserConfirmation,
    LocationFlow,
  },
  props: {
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
    researchData: {
      type: Object,
      default: () => ({}),
    },
    researchLogos: {
      type: Array,
      default: () => ([]),
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      stepStack: [1],
      hasLocation: true,
      locationUserSelected: null,
      hasInitialLocation: false,
      skipResearch: false,
    };
  },
  computed: {
    ...mapGetters({
      homeLocation: 'authentication/homeLocation',
      activeGrades: 'authentication/activeGrades',
      currentStudent: 'authentication/currentStudent',
      listOfMunicipalities: 'options/municipalities',
    }),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.restartProcess(newVal);
      },
    },
  },
  async mounted() {
    this.restartProcess();
    this.hasLocation = true;
    this.skipResearch = this.$route.query?.research === 'false';
    this.retrieveCampuses({ callback: null });
    window.addEventListener('message', (event) => {
      const actionMappings = {
        // Getters
        getConfiguration: () => {
          this.sendDataToResearch('setConfiguration', this.generateInitialData());
        },
        getCampuses: ({ data: searchTerm }) => this.searchCampus(searchTerm),
        getLocations: () => this.sendDataToResearch('setLocations', this.listOfMunicipalities),
        // Updates
        updateAnswers: ({ data }) => {
          trackMixPanel('click_Usuarios_senData', data);
          this.patchSurvey(
            { answers: data },
          );
        },
        setContext: ({ data }) => {
          this.patchSurvey(
            { context: data },
          );
        },
        close: ({ data }) => {
          this.$router.push({ path: '/map', query: data });
        },
        redirect: ({ data }) => {
          const { url, target = '_self' } = data;
          window.open(url, target);
        },
        back: () => this.backStep(),
        track: ({ data: { track: tag, data } }) => {
          const {
            treatment,
            intervention_label: { id: interventionLabelId },
          } = this.researchData;
          trackMixPanel(tag, {
            ...data,
            treatment,
            interventionLabelId,
          });
        },
        checkpoint: ({ data }) => {
          this.patchSurvey(
            { checkpoint: data },
          );
        },
        submit: ({ data }) => {
          this.patchSurvey(
            { answers: data, submitted: true },
          ).then(({ data: { submitted } }) => {
            this.sendDataToResearch('setSubmitted', submitted);
          }).catch(() => {
            this.sendDataToResearch('setSubmitted', false);
          });
        },
      };
      if (event.data.context === 'explorer') {
        actionMappings[event.data.action](event.data);
      }
    });
  },
  methods: {
    ...mapActions({
      setFinalLocationAddress: 'userRegister/setFinalLocationAddress',
      searchCampusesFromText: 'elasticSearch/searchCampusesFromText',
      retrieveCampuses: 'institutions/retrieveCampuses',
      changePlace: 'userRegister/changePlace',
    }),
    patchSurvey(data) {
      return reportsService.patchResearchInformation(
        { researchId: this.researchData.uuid, surveyId: this.researchData.survey.uuid },
        data,
      );
    },
    generateInitialData() {
      const { grades } = this.activeGrades;
      const {
        uuid: researchId,
        intervention_label: interventionLabel,
        treatment: interventionTreatment,
      } = this.researchData;

      const agreements = matchScholarships(
        { student: this.currentStudent, options: { forceOffer: true } },
      ).map(({ agreementId }) => agreementId);

      // If the survey exists and is not submitted, update the applicant_uuid
      if (!this.researchData.survey.submitted && this.researchData.survey.uuid) {
        reportsService.patchResearchInformation(
          { researchId, surveyId: this.researchData.survey.uuid },
          { applicant_uuid: this.currentStudent.uuid },
        );
      }

      return {
        grades,
        location: this.locationUserSelected,
        researchId,
        interventionLabel,
        interventionTreatment,
        tenant: TENANT_CONFIGURATION.ABBREVIATION.toUpperCase(),
        agreements,
      };
    },
    locationSelected($event) {
      this.locationUserSelected = $event;
    },
    searchCampus({ query, location }) {
      const filters = location ? [{ fieldname: 'commune', fieldvalue: location, exact: true }] : undefined;
      this.searchCampusesFromText({ searchTerm: query, requiredFields: ['campus_name', 'institution_code'], filters })
        .then((response) => {
          if (response.status === 200) {
            const { campuses } = response;
            this.sendDataToResearch('setCampuses', campuses);
          }
        });
    },
    isDraggable() {
      if (this.researchLocaleKeyRoot.includes('research-cl')) {
        return true;
      }
      if (this.hasLocation) {
        return false;
      }
      return true;
    },
    sendDataToResearch(action, value) {
      const iframe = document.getElementById('research-iframe');
      const message = {
        context: 'research',
        action,
        data: value,
      };

      iframe.contentWindow.postMessage(message, '*');
    },
    nextStep() {
      if (this.inStep(4)) {
        this.loadIframe();
      } else if (this.inStep(3)) {
        // TODO: Don't really understand this part
        if (this.homeLocation.source !== 'default') {
          const { details, lat, lng } = this.homeLocation;
          this.hasInitialLocation = true;
          const value = {
            formatted_address: details.address_details,
            geocodification_type: null,
            lat,
            lng,
            source: null,
            upper_location: {},
            street_name: null,
            street_number: null,
          };
          const place = {
            display: null,
            display1: details.city,
            display2: null,
            lat: null,
            lng: null,
            cityTitle: details.city,
            code_national_id: details.code_national_id,
            p_location_id: details.p_location_id,
          };
          this.changePlace({ place });
          this.setFinalLocationAddress({ address: value });
        } else {
          this.hasLocation = false;
        }
      }

      let nextStep = this.currentStep;

      // temporary fix for the research
      if (this.inStep(1)) {
        nextStep = this.currentStep + 2;
      } else {
        nextStep = this.currentStep + 1;
      }

      this.stepStack.push(nextStep);
      if (this.inStep(1) || this.inStep(2) || this.inStep(3) || this.inStep(4)) {
        trackMixPanel(`click_UsuariosPreCreados_${this.currentStep}_continue`);
      }
      return this.stepStack;
    },
    loadIframe() {
      if (this.skipResearch) {
        this.$router.push({ path: '/map', query: { vacanciesOff: true } });
        return;
      }

      const iframeContainer = document.getElementById('research-iframe-container');
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', CONFIG.researchUrl);
      iframe.setAttribute('id', 'research-iframe');
      iframe.setAttribute('name', 'researchIframe');
      iframe.setAttribute('class', 'iframe-size');
      iframeContainer.appendChild(iframe);
    },
    backStep() {
      const lastStep = this.stepStack.pop();
      if (lastStep === 5) {
        const iframe = document.getElementById('research-iframe');
        iframe.remove();
      }
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    restartProcess(customStep) {
      const newStep = customStep !== undefined ? customStep : this.step;
      this.stepStack = [newStep];
    },
    changeLocation() {
      this.hasLocation = false;
    },
  },
};
</script>
